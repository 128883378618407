import ReactGA from 'react-ga'

const TRACKING_ID = 'UA-47527307-1'

function init() {
    // Enable debug mode on the local development environment
    ReactGA.initialize(TRACKING_ID, { debug: false, titleCase: false })
}

function sendEvent(payload) {
    ReactGA.event(payload)
}

function sendPageview(path) {
    ReactGA.set({ page: path })
    ReactGA.pageview(path)
}

export default {
    init,
    sendEvent,
    sendPageview
}
