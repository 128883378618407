import React from 'react'
import analytics from '../../utils/Analytics'
import Navigation from '../Navigation'
import './Header.scss'

/**
 * Header component
 */
const Header = (props) => {
    const { type, leadIn, heading, copy, button, altImg } = props.data

    /**
     * @method onCall
     * Fires analytics event
     */
    const onCall = () => {
        analytics.sendEvent({
            category: 'Lead',
            action: 'Got Phone Call'
        })
    }

    const authUser = localStorage.authUser ? JSON.parse(localStorage.authUser) : false

    const finalHeadingCopy = authUser && authUser.name ? heading.replace('{{name}}', authUser.name) : ''

    return (
        <div className={`header ${type} ${altImg && 'alt-img'}`}>
            <div className="header__wrapper">
                <div className="main-logo">
                    <img alt="AVCR Logo" src="./images/Logo_original_WH.png" />
                </div>
                <div className="rightNav__wrapper">
                    <Navigation />
                </div>
            </div>
            <div className="header__text">
                <h1 className="header__text-leadIn">{leadIn}</h1>
                <h2 className="header__text-heading">{finalHeadingCopy}</h2>
                <p className="header__text-copy">{copy}</p>
                {button && (
                    <a href={button.url} target="_blank" rel="noreferrer" className="header__text-button button phone" onClick={() => onCall()}>
                        {button.text}
                    </a>
                )}
            </div>
        </div>
    )
}

export default Header
