import { initializeApp } from 'firebase/app'
// import 'firebase/auth'
// import 'firebase/firestore'
// import 'firebase/storage'
import { getAnalytics } from 'firebase/analytics'

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
}

const app = initializeApp(config)

class Firebase {
    constructor() {
        /* Helper */
        // console.log(app);
        // this.auth = app.auth()
        // this.db = app.firestore()
        // this.storage = app.storage()
        // this.fieldValue = this.db.FieldValue
        // this.emailAuthProvider = this.auth.EmailAuthProvider

        /* Firebase APIs */

        getAnalytics(app)
    }

    /**
     * @method createUser
     * @param {*} email
     * @param {*} password
     */
    createUser = (email, password) => this.auth.createUserWithEmailAndPassword(email, password)

    /**
     * @method signIn
     * @param {*} email
     * @param {*} password
     */
    signIn = (email, password) => this.auth.signInWithEmailAndPassword(email, password)

    /**
     * @method signOut
     */
    signOut = () => this.auth.signOut()

    /**
     * @method passwordReset
     * @param {*} email
     */
    passwordReset = (email) => this.auth.sendPasswordResetEmail(email)

    /**
     * @method passwordUpdate
     * @param {*} password
     */
    passwordUpdate = (password) => this.auth.currentUser.updatePassword(password)

    /**
     * @method verifyEmail
     * @param {*} code
     */

    verifyEmail = (code) => this.auth.applyActionCode(code)

    verifyPasswordResetCode = (code) => this.auth.verifyPasswordResetCode(code)

    confirmPasswordReset = (actionCode, newPassword) => this.auth.confirmPasswordReset(actionCode, newPassword)

    sendEmailVerification = () => this.auth.currentUser.sendEmailVerification()

    /**
     * @method onAuthUserListener
     * Merge Auth and DB User API
     * @param {*} next
     * @param {*} fallback
     */
    onAuthUserListener = (next, fallback) =>
        this.auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                this.user(authUser.uid)
                    .get()
                    .then((snapshot) => {
                        const dbUser = snapshot.data()
                        authUser = {
                            uid: authUser.uid,
                            email: authUser.email,
                            emailVerified: authUser.emailVerified,
                            providerData: authUser.providerData,
                            ...dbUser
                        }
                        next(authUser)
                    })
            } else {
                fallback()
            }
        })
    // Firestore databases
    mail = () => this.db.collection('mail')
    user = (uid) => this.db.doc(`users/${uid}`)
    users = () => this.db.collection('users')
    uploadFile = (file, uid) => this.storage.ref().child(`${uid}-${file.name}-${new Date().toISOString()}`).put(file)
    getUserData = (uid) => this.db.doc(`users/${uid}`).get()
}

export default Firebase
