import React from 'react'
import './Tile.scss'

/**
 * Tile component
 * @param {*} props
 */
const Tile = (props) => {
    const { data } = props
    return (
        <div className={`tile ${data.special && 'tile__special'}`}>
            {data.img && <img src={data.img} alt="logo" />}

            {data.heading && <h3 className="tile__title" dangerouslySetInnerHTML={{ __html: data.heading }}></h3>}

            {data.description && <span className="tile__desc" dangerouslySetInnerHTML={{ __html: data.description }}></span>}

            {data.imageText && (
                <div className="tile__imageText">
                    <div className="tile__imageText-image">{data.imageText.image}</div>
                    <div className="tile__imageText-text">{data.imageText.text}</div>
                </div>
            )}

            {data.featuredBox && (
                <div className="tile__featuredBox">
                    <div className="tile__featuredBox-price" dangerouslySetInnerHTML={{ __html: data.featuredBox.price }}></div>
                    <div className="tile__featuredBox-text">{data.featuredBox.text}</div>
                </div>
            )}

            {data.list && (
                <div className="tile__list">
                    <ul>
                        {data.list.map((item, i) => (
                            <li key={i}>{item.text}</li>
                        ))}
                    </ul>
                </div>
            )}

            {data.warning && <div className="tile__notice">{data.warning}</div>}

            {data.text && <div className="tile__text">{data.text}</div>}

            {data.button && (
                <div className="cta tile__button tile__button">
                    <a href={`${data.button.url}?membership=${data.button.slug}&access=new`}>{data.button.text}</a>
                </div>
            )}
        </div>
    )
}

export default Tile
