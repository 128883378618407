import React from 'react'
import PropTypes from 'prop-types'

import { AppProvider } from './GlobalContext'

const ContextProvider = ({ children }) => <AppProvider>{children}</AppProvider>

ContextProvider.propTypes = {
    children: PropTypes.node.isRequired
}

export default ContextProvider
