import React, { Component } from 'react'
import './Footer.scss'

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <img src="images/Logo_WH.png" alt="Logo" />
                <div className="footer__phone">
                    <a href="tel:50684852020">+506 8485-2020</a>
                </div>
                <div className="footer__email">
                    <a href="mailto:info@crgruas.com">info@crgruas.com</a>
                </div>
                <p className="footer_legend">Derechos Reservados de AVCR Asistencia Vial Costa Rica.</p>
            </div>
        )
    }
}

export default Footer
