import React from 'react'
import useGoogleAnalytics from './hooks/useAnalytics'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import ContextProvider from './utils/AppContext/ContextProvider'
import HomePage from './pages/Home'
import * as ROUTES from './constants/routes'
import './App.scss'
import './components/ContactForm/ContactForm.scss'
import Spinner from './components/Spinner'

/**
 * App Component
 */

function App() {
    /**
     * @method Routes
     * Main application routes
     */
    const Routes = () => {
        // GA hook init
        useGoogleAnalytics()

        // <Route path={ROUTES.MEMBERSHIPS} component={MembershipsPage} />
        // <Route path={ROUTES.PASSWORD_RESET} component={PasswordResetPage} />
        // <Route path={ROUTES.PASSWORD_RESET_CONFIRM} component={PasswordResetConfirmPage} />
        // <Route path={ROUTES.SIGN_IN} component={SignInPage} />
        // <Route path={ROUTES.DASHBOARD} component={DashboardPage} />
        // <Route path={ROUTES.ACTIONS} component={FireAction} />
        // <Route path={ROUTES.TERMS} component={TermsPage} />

        return (
            <>
                <Route exact path={ROUTES.HOME} component={HomePage} />
            </>
        )
    }

    return (
        <div className="App">
            <ContextProvider>
                <Spinner />
                <Router>
                    <Routes />
                </Router>
            </ContextProvider>
        </div>
    )
}

export default App
