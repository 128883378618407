import React from 'react'
import analytics from '../../utils/Analytics'
import { Link } from 'react-router-dom'
import './Story.scss'

/**
 * Story component
 * @param props
 */
const Story = (props) => {
    //Get props
    const { heading, copy, img, button } = props.data

    /**
     * @method onCall
     * Fires analytics event
     */
    const onCall = () => {
        analytics.sendEvent({
            category: 'Lead',
            action: 'Got Phone Call'
        })
    }

    return (
        <div id={props.id} className="story">
            <div className="story__wrapper">
                <div className="story__text">
                    <h3 className="story__leadIn">{heading.leadIn}</h3>
                    <p className="story__heading" dangerouslySetInnerHTML={{ __html: heading.text }}></p>
                    <p className="story__copy">{copy}</p>
                    {button && (
                        <Link to={button.url} className="header__text-button button phone story__call" onClick={() => onCall()}>
                            {button.text}
                        </Link>
                    )}
                </div>
                <div className="story__image">
                    <img src={img.url} alt={img.alt} />
                </div>
            </div>
        </div>
    )
}

export default Story
