import React from 'react'
import Tile from '../Tile'
import './FeaturedIcons.scss'

/**
 * Featured Icons component
 * @param {*} props
 */
const FeaturedIcons = (props) => {
    // Get props
    const { id, data } = props

    return (
        <div id={id} className={`featuredIcons ${data.variants}`}>
            <div className="featuredIcons__wrapper">
                <div className="featuredIcons__heading">{data.heading}</div>
                <div className="featuredIcons__grid">
                    {data.items.map((tile, i) => (
                        <Tile key={i} data={tile} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default FeaturedIcons
