import React, { createContext, useState, useMemo, useContext } from 'react'

const AppContext = createContext()

export const AppProvider = (props) => {
    const [state, setState] = useState({
        isLoading: false,
        user: null
    })

    const showLoader = () =>
        setState({
            ...state,
            isLoading: true
        })

    const hideLoader = () =>
        setState({
            ...state,
            isLoading: false
        })

    const setUser = (user) => {
        setState({
            ...state,
            user
        })
        localStorage.setItem('authUser', user ? JSON.stringify(user) : user)
    }

    const setComsumerData = () => ({
        showLoader,
        hideLoader,
        setUser,
        state
    })

    const consumerValue = useMemo(setComsumerData, [state])

    return <AppContext.Provider value={consumerValue} {...props} />
}

export const AppConsumer = () => {
    const context = useContext(AppContext)
    if (!context) {
        throw new Error('AppConsumer must be inside AppContext provider')
    }
    return context
}
