import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { NavHashLink } from 'react-router-hash-link'
import * as ROUTES from '../../constants/routes'
import { AppConsumer } from '../../utils/AppContext/GlobalContext'
import { withFirebase } from '../../utils/Firebase'

import './Navigation.scss'

/**
 * Navigation component
 */
const Navigation = (props) => {
    // State
    const [isNavOpen, setIsNavOpen] = useState(false)
    const authUser = localStorage.authUser ? JSON.parse(localStorage.authUser) : false
    const { setUser } = AppConsumer()
    const { firebase } = props

    return (
        <>
            <nav className={isNavOpen ? `navigation open` : `navigation`}>{authUser ? <NavigationAuth firebase={firebase} setUser={setUser} /> : <NavigationNonAuth setIsNavOpen={setIsNavOpen} />}</nav>

            <div className="navigation__social">
                <div className="facebookBtn">
                    <a href="https://www.facebook.com/GruasAVCR" target="_blank" rel="noopener noreferrer">
                        Facebook
                    </a>
                </div>
            </div>

            <div className="navigation__mobile">
                <div className={isNavOpen ? 'navigation__mobile-trigger open' : 'navigation__mobile-trigger'} onClick={() => setIsNavOpen(!isNavOpen)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </>
    )
}
/**
 * Authenticated navigation
 * @param {*} props
 */
const NavigationAuth = (props) => (
    <ul>
        <li>
            <Link to={ROUTES.DASHBOARD}>DASHBOARD</Link>
        </li>
        {/* <li>
            <Link to={ROUTES.PROFILE}>PERFIL</Link>
        </li> */}
        <li>
            <a
                href="/"
                onClick={() => {
                    props.setUser(null)
                    props.firebase.signOut()
                }}
            >
                Salir
            </a>
        </li>
    </ul>
)

/**
 * Non authenticated navigation
 * @param {*} props
 */
const NavigationNonAuth = (props) => (
    <ul>
        <li>
            <NavHashLink smooth to={`${ROUTES.HOME}#beneficios`} onClick={() => props.setIsNavOpen(false)}>
                BENEFICIOS
            </NavHashLink>
        </li>
        <li>
            <NavHashLink smooth to={`${ROUTES.HOME}#servicios`} onClick={() => props.setIsNavOpen(false)}>
                SERVICIOS
            </NavHashLink>
        </li>
        {/* <li>
            <NavHashLink smooth to={`${ROUTES.HOME}#contacto`} onClick={() => props.setIsNavOpen(false)}>
                CONTACTO
            </NavHashLink>
        </li> */}
    </ul>
)

export default withFirebase(Navigation)
