import React from 'react'
import Tile from '../Tile'
import './Tiles.scss'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

/**
 * Tiles component
 * @param {*} props
 */
const Tiles = (props) => {
    // Get props
    const { id, data } = props

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1023
            },
            {
                breakpoint: 10000,
                settings: 'unslick'
            }
        ]
    }

    return (
        <div id={id} className={`tiles ${data.variants}`}>
            <Slider className="tiles__wrapper" {...settings}>
                {data.items.map((tile, i) => (
                    <Tile key={i} data={tile} />
                ))}
            </Slider>
        </div>
    )
}

export default Tiles
