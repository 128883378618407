import React from 'react'

import Footer from '../../components/Footer'
import FeaturedIcons from '../../components/FeaturedIcons'
// import ContactForm from '../../components/ContactForm'
import FeaturedBox from '../../components/FeaturedBox'
import Story from '../../components/Story'
import Tiles from '../../components/Tiles'
import Header from '../../components/Header'

import pageData from './Home.json'

/**
 * Homepage component
 */
const HomePage = () => {
    // Get props
    const { header, tiles, story, featuredBox, featuredIcons } = pageData

    return (
        <div className="homepage">
            <Header data={header} />
            <Tiles id="beneficios" data={tiles} />
            <Story id="central" data={story} />
            {/* <FeaturedBox id="membresias" data={featuredBox} /> */}
            <FeaturedIcons id="servicios" data={featuredIcons} />
            {/* <ContactForm id="contacto" /> */}
            <Footer />
        </div>
    )
}

export default HomePage
